import * as React from "react";

export interface LogoProps {
  width: React.CSSProperties["width"];
  height: React.CSSProperties["height"];
  src?: string;
  alt?: string;
}

function Logo(props: LogoProps) {
  const { src, alt, width, height } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width,
        height,
      }}
    >
      <img
        src={src || "static/img/logo-wa.png"}
        alt={alt || "Logo"}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    </div>
  );
}

export default Logo;

const env = process.env;

/**
 * Server-side environements.
 *
 * @description You must bind .env keys NOT prefixed with REACT_APP_ and always use serverEnv in the code to use these environment variables.
 * All those environment variables MUST NOT contains secrets and CAN ONLY expose values that can be PUBLICLY VISIBLE.
 * To implements secrets, you MUST implement them in the secrets.json file, implemented in secrets.ts.
 */
export const serverEnv = {
  adminEmails: env.ADMIN_EMAILS,
  coreTeamDomain: env.CORE_TEAM_DOMAIN,
  imgOptimizerHost: env.IMG_OPTIMIZER_HOST,
  nodeEnv: env.NODE_ENV,
  reactAppGithubAppName: env.REACT_APP_GITHUB_APP_NAME,
  reactAppGithubClientId: env.REACT_APP_GITHUB_CLIENT_ID,
};

if (serverEnv.nodeEnv === "development") {
  console.log("serverEnv", serverEnv);
}

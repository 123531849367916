import * as React from "react";
import Logo, { LogoProps } from "./Logo";

export type LogoFullProps = LogoProps;

function LogoFull(props: LogoFullProps) {
  return <Logo src={"static/img/logo-wa-full.png"} {...props} />;
}

export default LogoFull;

import { clientEnv } from "@/wab/client/env";
import {
  UserManagerSettings,
  UserManager,
  WebStorageStateStore,
} from "oidc-client-ts";

function createZitadelAuth(
  zitadelConfig: Partial<UserManagerSettings> & {
    organization_resource_id?: string;
    project_resource_id?: string;
  }
) {
  const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    loadUserInfo: true,
    authority: `${zitadelConfig.authority}`,
    client_id: `${zitadelConfig.client_id}`,
    redirect_uri: `${zitadelConfig.redirect_uri}`,
    response_type: "code",
    scope:
      zitadelConfig.scope ??
      `openid profile email offline_access ${
        zitadelConfig.organization_resource_id
          ? `urn:zitadel:iam:org:id:${zitadelConfig.organization_resource_id}`
          : ""
      } ${
        zitadelConfig.project_resource_id
          ? `urn:zitadel:iam:org:project:id:${zitadelConfig.project_resource_id}:aud urn:zitadel:iam:org:projects:roles`
          : ""
      }`,
    prompt: zitadelConfig.prompt ?? "",
    post_logout_redirect_uri: `${zitadelConfig.post_logout_redirect_uri}`,
    response_mode: "query",
  });

  const signin = () => {
    return userManager.signinRedirect();
  };

  const signout = () => {
    return userManager.signoutRedirect();
  };

  return {
    signin,
    signout,
    userManager,
  };
}

export const zitadel = createZitadelAuth({
  authority: clientEnv.zitadelAuthority,
  client_id: clientEnv.zitadelClientId,
  organization_resource_id: clientEnv.zitadelOrgResourceId,
  project_resource_id: clientEnv.zitadelProjectResourceId,
  redirect_uri: clientEnv.zitadelRedirectUri,
  post_logout_redirect_uri: clientEnv.zitadelPostLogoutRedirectUri,
});
